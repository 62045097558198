'use strict'

###*
 # @ngdoc object
 # @name mundoAdmin.notifications.controller:NotificationsCtrl

 # @description

###
class NotificationsCtrl
  ### @ngInject ###
  constructor: ->
    @ctrlName = 'NotificationsCtrl'

angular
  .module('mundoAdmin.notifications')
  .controller 'NotificationsCtrl', NotificationsCtrl
